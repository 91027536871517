var ForwardCookies = {
    /**
        * Get cookie raw
        * @param {string} cookieName - Name of the cookie to get as raw
    */
    getCookie(cookieName) {
        const value = "; " + document.cookie;
        const parts = value.split("; " + cookieName + "=");

        if (parts.length === 2) {
            return parts.pop().split(";").shift();
        }
    },

    /**
        * Delete cookie
        * @param {string} cookieName - Name of the cookie to delete
    */
    deleteCookie(cookieName) {
        var currentHost = location.hostname.replace('www', '');
        document.cookie = cookieName + '=; expires=Thu, 18 Dec 1990 12:00:00 UTC; domain='+currentHost+'; path=/';

        console.warn('Deleted Cookie: ' + cookieName + ' from: '+currentHost);
    },

    /**
        * Set Cookie value to a new value
        * @param {string} cookieName - Name of the cookie to create
        * @param {string} value - Stringified value to add on Cookie
        * @param {number} expiration - Expiration coookie days | defaults 30
        * @param {string} domain - Optional if needs a custom domain
    */
    setCookie(cookieName, value, expiration = 30, domain) {
        this.deleteCookie(cookieName);

        const getDate = new Date();
        getDate.setTime(getDate.getTime() + (expiration * 24 * 60 * 60 * 1000));
        let expirationDate = "expires="+ getDate.toUTCString();

        const currentHost = domain ? domain : location.hostname.replace('www', '');

        document.cookie = cookieName+'='+value+';'+expirationDate+';'+'domain='+currentHost+';path=/';

        console.warn('Created Cookie: ' + cookieName + ' from: '+currentHost);
    },

    /**
        * Add cookie to URL
        * @param {string} url - Origin url that will erceive cookie as param
        * @param {string} cookieName - Name of the cookie to add in url
    */
    addCookieToUrl(url, cookieName, delimiter) {
        const COOKIE = delimiter ? decodeURIComponent(this.getCookie(cookieName)).split(delimiter)[1] :this.getCookie(cookieName);

        let cookieAsParam = url.includes("?")
            ? "&" + cookieName + "=" + (delimiter ? encodeURIComponent(COOKIE) : COOKIE)
            : "?" + cookieName + "=" + (delimiter ? encodeURIComponent(COOKIE) : COOKIE);

        let fullUrlOutput = url + cookieAsParam;

        return fullUrlOutput;
    },

    /**
        * Parse raw cookie string
        * @param {string} cookie - Raw cookie to parse
        * @param {string} delimiter - Delimiter string to split and get last part from array generated
    */
    parseCookieString(cookie, delimiter) {
        let parsedUrlCookie= null;
        if (delimiter && cookie.includes(delimiter) ) {
            parsedUrlCookie = JSON.parse(decodeURIComponent(cookie).split(delimiter)[1]);
        } else {
            parsedUrlCookie = JSON.parse(decodeURIComponent(cookie));
        }

        return {
            json: parsedUrlCookie,
            encoded: cookie,
        };
    },

    /**
        * Get cookie data from url
        * @param {string} urlCookieParam - Name of the cookie at url as param
        * @param {string} delimiter - Optional is needed split cookie to get data
        * @param {string} url - Optional if is not the current url
    */
    getCookieFromUrl(urlCookieParam, delimiter, url = location.href) {
        const URLCOOKIE = url.split(urlCookieParam + "=")[1];
        const output = this.parseCookieString(URLCOOKIE, delimiter ? delimiter : '');

        return output;
    }
}